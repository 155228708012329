<template>
  <a
    v-if="$locale"
    :class="`language-button ${_for}`"
    @click="languageSelector"
    active="button"
    href="#"
    name="Language"
    :aria-label="$locale['language_button']"
  >
    <span class="language-button-flag" :style="`--flag:url(${$locale['language_flag']})`"></span>
    <span class="language-button-text">Language</span>
  </a>
</template>

<script>
export default {
  props: ["_for"],
};
</script>

<style lang="scss" scoped>
.language-button {
  display: inline-flex;
  align-items: center;
  gap: $mpadding/2;
  cursor: pointer;
  &-flag {
    width: $mpadding * 1.5;
    height: $mpadding * 1.5;
    background-color: #fff;
    border-radius: 50%;
    background-image: var(--flag);
    background-size: 170%;
    background-position: center center;
    box-shadow: $dshadow;
  }
  &.login_footer &-flag {
    width: 1em;
    height: 1em;
  }
}
</style>
